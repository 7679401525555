import React, { useEffect, useState } from 'react'
import { Col, Card, Row, Progress, Avatar, Tooltip, Drawer, Button, App, DatePicker, Form, Input, Space, Table, Tag, Dropdown, Select } from 'antd';
import { AntDesignOutlined, UserOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import request from '../../utils/request'
import dayjs from 'dayjs';

const RedPackFrom = ({ data, savetype, setOpen }) => {

    const [form] = Form.useForm();
    const { message, modal, notification } = App.useApp();
    const { RangePicker } = DatePicker;

    const btnClickHandle = async (values) => {
        let url = '/admin/web/promotion/luckymoney/save';
        if (savetype === 'edit') {
            url = '/admin/web/promotion/luckymoney/save?id=' + data.id;
        }
        const ret = await request.post(url, values);
        switch (ret.data.code) {
            case 200:
                message.success(ret.data.msg);
                setOpen(false);
                break;
            case 400:
                message.error(ret.data.msg);
                break;
            default:
                break;
        }
    }
    if (savetype === 'create') {//如果是新增添加一个默认值
        form.resetFields();
        form.setFieldsValue({ send_name: '蓝牛建材' });
    } else {//如果是编辑则使用传递的数据作为默认值
        // form.setFieldsValue({ batchno: data.batchno, act_name: data.act_name, send_name: data.send_name, wishing: data.wishing, remark: data.remark, act_time: [data.act_begintime, data.act_endtime], total: data.total });
        form.setFieldsValue({ batchno: data.batchno, act_name: data.act_name, send_name: data.send_name, wishing: data.wishing, remark: data.remark, act_time: [dayjs(data.act_begintime * 1000), dayjs(data.act_endtime * 1000)], total: data.total });
    }
    return (
        <div>
            <Form
                form={form}
                labelCol={{
                    span: 4,
                }}
                wrapperCol={{
                    span: 14,
                }}
                layout="horizontal"
                style={{
                    maxWidth: 600,
                }}
                onFinish={(values) => {
                    btnClickHandle(values)
                }}
            >
                <Form.Item
                    label="活动批次号"
                    extra="仅用于方便区分红包促销活动,保存后不可修改"
                    rules={[
                        {
                            required: true,
                            message: '批次号不能为空',
                        },
                    ]}
                    name='batchno'
                >
                    <Input disabled={savetype === 'edit' ? true : false} />
                </Form.Item>
                <Form.Item
                    label="活动产品"
                    extra="仅用于方便区分红包促销活动,保存后不可修改"
                    rules={[
                        {
                            required: true,
                            message: '产品名称不能为空',
                        },
                    ]}
                    name='goodsname'
                >
                    <Select
                        defaultValue="大板"
                        style={{
                            width: 120,
                        }}
                        options={[
                            {
                                value: '一型',
                                label: '一型',
                            },
                            {
                                value: '二型',
                                label: '二型',
                            },
                            {
                                value: '三型',
                                label: '三型',
                            },
                            {
                                value: '大板',
                                label: '大板',
                            },
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    label="活动名称"
                    extra="act_name字段,32字符以内，示例：猜灯谜抢红包活动"
                    rules={[
                        {
                            required: true,
                            message: '活动名称不能为空!',
                        },
                    ]}
                    name='act_name'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="发送者昵称"
                    extra="send_name字段,32字符以内，示例：蓝牛建材"
                    rules={[
                        {
                            required: true,
                            message: '发送者昵称不能为空!',
                        },
                    ]}
                    name='send_name'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="红包祝福语"
                    extra="wishing字段,128字符以内，示例：感谢您参加猜灯谜活动，祝您元宵节快乐！"
                    rules={[
                        {
                            required: true,
                            message: '红包祝福语不能为空!',
                        },
                    ]}
                    name='wishing'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="备注信息"
                    extra="remark字段,256字符以内，示例：猜越多得越多，快来抢"
                    rules={[
                        {
                            required: true,
                            message: '备注信息不能为空!',
                        }
                    ]}
                    name='remark'
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="起止时间"
                    extra="扫码领取红包的开始和结束时间"
                    name='act_time'
                >
                    <RangePicker />
                </Form.Item>
                <Form.Item
                    label="红包数量"
                    extra="生成的红包数量，如果想生成10个微信二维码红包，请输入10，保存后不可修改"
                    name='total'
                    rules={[
                        {
                            required: true,
                            message: '红包发送数量不能为空',
                        },
                    ]}
                >
                    <Input disabled={savetype === 'edit' ? true : false} />
                </Form.Item>
                <Form.Item
                    nostyle={true}
                    label=" "
                    colon={false}
                >
                    <Button type="primary" htmlType='submit'>保存</Button>
                </Form.Item>
            </Form>
        </div>
    )
}

export default RedPackFrom