import React, { useState } from 'react';
import { Table, Button, message, App } from 'antd';
import dayjs from 'dayjs'


const TableList = ({ data, total, callBack, selectedRowKeys }) => {
    const { message } = App.useApp();

    const columns = [
        {
            title: '所属商城',
            width: 80,
            dataIndex: 'stores',
            render: () => (<div>蓝海</div>)
        },
        {
            title: '手机号',
            width: 120,
            dataIndex: 'mobile',
        },
        {
            title: '商城ID',
            width: 90,
            dataIndex: 'cusshopid',

        },
        // {
        //     title: '金蝶编码',
        //     width: 100,
        //     dataIndex: 'kisbn',
        //     key: 'kisbn',
        //     fixed: 'left',
        // },
        {
            title: '注册日期',
            width: 100,
            dataIndex: 'regtime',
            render: (value) => {
                // return dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
                return dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD')
            }
            // sorter: true,
        },
        // {
        //     title: '首次下单日期',
        //     dataIndex: 'firstordertime',
        //     width: 120,
        //     key: 'firstordertime',
        //     render: (value) => {
        //         // return dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
        //         return dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD')
        //     }
        // },
        {
            title: '最后下单日期',
            dataIndex: 'lastordertime',
            width: 120,
            render: (value) => {
                // return dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')
                return dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD')
            }
        },
        {
            title: '金额(元)',
            dataIndex: 'price',
            width: 100,
            render: (value) => {
                return '￥' + (value / 1000).toFixed(2)
            }
        },
        {
            title: '单数',
            dataIndex: 'totalcount',
            width: 50,
        },
        {
            title: '销售组',
            dataIndex: 'aid',
            width: 100,
        },
        {
            title: '所在地区',
            dataIndex: 'address',
            width: 200,
            render: (value, record, index) => record.province + '/' + record.city + '/' + record.county,
        },
        {
            title: '更多操作',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: () => <Button type="link" onClick={() => {
                message.success('功能开发中');
            }}>更多操作</Button>,
        },
    ];
    const link = () => {

    }
    const onSelectChange = (newSelectedRowKeys) => {
        callBack(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    return (
        <Table rowSelection={rowSelection} columns={columns} dataSource={data} pagination={{ total: total, defaultPageSize: 10, defaultCurrent: 1, showTotal: (total) => `总计${total}条`, pageSizeOptions: [10, 100, 500, 1000] }} />
    )

}
export default TableList;