import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom';
import SigninForm from './components/signinForm'
const Signin = () => {
    const logined = useSelector(state => state.sign.logined);

    if (!logined) {
        return (
            <div className=' w-screen h-screen bg-blue-500 flex justify-center items-center'>
                <div className=' w-150 h-120 bg-white rounded-xl'>
                    <div className=' h-32 w-full text-center text-3xl flex justify-center items-center'>蓝海商家后台</div>
                    <div className='w-full h-full flex justify-center'>
                        <SigninForm />
                    </div>
                </div>
            </div>
        )
    } else {
        return <Navigate to="/admin/dashboard" />
    }
}

export default Signin