import { createSlice } from "@reduxjs/toolkit";

const taskSlice = createSlice({
    name: "task",
    initialState: {
        loading: false,//加载状态
        reload: false,//刷新页面
        msg: '',//提示信息
        stepone: {},//表单一的数据
        steptwo: [],//关键词
        stepthree: [],//商品属性
        stepfour: [],//主图
        stepfive: [],//视频
        stepsix: '',//编辑器
        stepseven: {},//发布配置
        // stepone: { 'titlea': '', 'titleb': '', 'titlec': '', 'titled': '', 'titlee': '', 'titlef': '' },
        title: [],
        name: {}
    },
    reducers: {
        //更新表单一
        updateStepOne: (state, { payload }) => {
            // state.one.push({ 'titlea': action.payload })
            // state.name = { 'aaa': payload }
            // state.title.push(payload)
            state.stepone = { ...state.stepone, ...payload }
        },
        updateStepTwo: (state, { payload }) => {
            state.steptwo = payload;
        },
        updateStepThree: (state, { payload }) => {
            state.stepthree = payload.goodsprop;
        },
        updateStepFour: (state, { payload }) => {
            if (payload.checked === true) {
                state.stepfour.push({ id: payload.id })
            } else {
                const index = state.stepfour.findIndex(p => p.id === payload.id);
                state.stepfour.splice(index, 1);
            }
            state.stepfour = state.stepfour.filter(function (item, index, arr) {
                //当前元素，在原始数组中的第一个索引==当前索引值，否则返回当前元素
                return state.stepfour.indexOf(item, 0) === index;
            });
        },
        updateStepFive: (state, { payload }) => {
            state.stepfive = payload;
        },
        updateStepSix: (state, { payload }) => {
            state.stepsix = payload;
        },
        updateStepSeven: (state, { payload }) => {
            state.stepseven = { ...state.stepseven, ...payload }
        },
        cleanStep: (state, { payload }) => {
            state.stepone = {};
            state.steptwo = [];//关键词
            state.stepthree = [];//商品属性
            state.stepfour = [];//主图
            state.stepfive = [];//视频
            state.stepsix = '';//编辑器
            state.stepseven = {};//发布配置
        },
        //添加任务请求
        addTaskRequest: (state, { payload }) => {
            state.loading = true;
        },
        //添加任务成功
        addTaskSuccess: (state, { payload }) => {
            state.loading = false;
            state.msg = payload.msg;
        },
        //添加任务失败
        addTaskFail: (state, { payload }) => {
            state.loading = false;
            state.msg = payload;
        },
        //清除提示消息
        cleanMsg: (state, { payload }) => {
            state.msg = '';
        },
        //添加任务请求
        updateTaskRequest: (state, { payload }) => {
            state.loading = true;
        },
        //添加任务成功
        updateTaskSuccess: (state, { payload }) => {
            state.loading = false;
            //清空任务数据
            state.stepone = {};
            state.steptwo = [];//关键词
            state.stepthree = [];//商品属性
            state.stepfour = [];//主图
            state.stepfive = [];//视频
            state.stepsix = '';//编辑器
            state.stepseven = {};//发布配置
            state.msg = payload.msg;
        },
        //添加任务失败
        updateTaskFail: (state, { payload }) => {
            state.loading = false;
            state.msg = payload;
        },
    }
});

export const taskReducer = taskSlice.reducer;
export const { updateStepOne, updateStepTwo, updateStepThree, updateStepFour, updateStepFive, updateStepSix, updateStepSeven, addTaskRequest, addTaskFail, addTaskSuccess, cleanMsg, updateTaskRequest, updateTaskSuccess, updateTaskFail, cleanStep } = taskSlice.actions;