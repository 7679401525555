import React, { useEffect, useState, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Input, Form, Space, Table, Image, Button, Card, App, Select, Drawer, Timeline, Badge } from 'antd';
import request from '../../utils/request'
import dayjs from 'dayjs'

const items = [
    {
        key: '1',
        label: 'Action 1',
    },
    {
        key: '2',
        label: 'Action 2',
    },
];

const GoodsList = () => {

    const [dataSource, setDataSource] = useState([])
    const [expanded, setExpanded] = useState([]);
    const { message } = App.useApp();
    const { Option } = Select;


    const loadKisGoodsDataSource = async (filter) => {
        const res = await request.post('/admin/web/kis/goods/list', filter)
        switch (res.data.code) {
            case 200:
                setDataSource(res.data.data)
                break;
            case 401:
                break;
            default:
                break;
        }
    }
    /**
     * 异步更新金蝶物料
     */
    const asyncUpdataKisGoods = async () => {
        const res = await request.post('/admin/web/kis/goods/update/async')
        switch (res.data.code) {
            case 200:
                message.success(res.data.msg);
                break;
            case 401:
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        loadKisGoodsDataSource({});
    }, [])

    const loadShopGoodsDataSource = async (kid) => {
        const res = await request.post('/admin/web/shop/goods/list', { kid })
        return res.data.data
        // return []
    }

    // const expandedRowRender = (record, index) => {
    //     // const data = [];
    //     // const data = request.post('/admin/web/shop/goods/list', { kid: record.id })
    //     // console.log('///////////////data.data.data//////////////');
    //     // console.log(data.data.data);
    //     // console.log('//////////////data.data.data///////////////');


    //     const ret = loadShopGoodsDataSource(record.id)
    //     const data = ret;
    //     // data.push(ret.data.data)
    //     const columns = [
    //         {
    //             title: '物料名称',
    //             dataIndex: 'date',
    //         },

    //         {
    //             title: '活动名称',
    //             dataIndex: 'name',
    //         },
    //         {
    //             title: '起订量',
    //             key: 'state',
    //             render: () => <Badge status="success" text="Finished" />,
    //         },
    //         {
    //             title: '价格',
    //             key: 'state',
    //             render: () => <Badge status="success" text="Finished" />,
    //         },
    //         {
    //             title: '时间',
    //             dataIndex: 'upgradeNum',
    //         },
    //         {
    //             title: '操作',
    //             dataIndex: 'operation',
    //             key: 'operation',
    //             render: () => (
    //                 <Space size="middle">
    //                     <a>Pause</a>
    //                     <a>Stop</a>
    //                     <Dropdown
    //                         menu={{
    //                             items,
    //                         }}
    //                     >
    //                         <a>
    //                             More <DownOutlined />
    //                         </a>
    //                     </Dropdown>
    //                 </Space>
    //             ),
    //         },
    //     ];

    //     return (data && <Table columns={columns} dataSource={data} pagination={false} />);
    // };
    const expandableProps = React.useMemo((record, index) => {
        const columns = [
            {
                title: '商城物料编码',
                width: 150,
                dataIndex: 'syscode',
            },
            {
                title: '物料名称',
                dataIndex: 'productname',
            },
            {
                title: '促销活动名称',
                width: 200,
                dataIndex: 'promotionname',
            },
            {
                title: '促销价格',
                width: 100,
                dataIndex: 'promotionprice'
            },
            {
                title: '促销结束时间',
                width: 200,
                dataIndex: 'promotionendtime',
            }
        ];

        return {
            columnWidth: 48,
            expandedRowRender: (record, index) => {
                // // const data = [];
                // request.post('/admin/web/shop/goods/list', { kid: record.id }).then((res) => {
                //     setExpanded(res.data.data)
                //     // data.concat(res.data.data);
                // })
                return (
                    < Table columns={columns} dataSource={record.shopgoodslist} pagination={false} />
                )
            },
            // rowExpandable: (record) => <Table columns={columns} dataSource={[]} pagination={false} />,
        };
    }, []);

    const columns = [
        {
            title: '商品图片',
            width: 90,
            dataIndex: 'defaultimg',
            render: (value, record, index) => <Image width={50} src={`https://lhkm.oss-cn-qingdao.aliyuncs.com${value}`} />,
        },
        Table.EXPAND_COLUMN,
        {
            title: '商品名称',
            dataIndex: 'kisname',
        },
        {
            title: '物料编码',
            dataIndex: 'kisbn',
        },
        {
            title: '商品价格',
            width: 100,
            dataIndex: 'kisprice',
            render: (value, record, index) => "￥" + (value / 1000).toFixed(2),
        },
        {
            title: '所属商城',
            width: 100,
            dataIndex: 'kisaccount',
        },
        {
            title: '商品分组',
            width: 200,
            dataIndex: 'grouppath',
        },
        {
            title: '更新时间',
            width: 120,
            dataIndex: 'lastupdatetime',
            render: (value, record, index) => (dayjs(value * 1000).locale('zh-cn').format('YYYY-MM-DD HH:mm:ss')),
        },
        {
            title: '操作',
            width: 100,
            key: 'operation',
            render: () => <a>更新</a>,
        },
    ];

    const rowSelection = {
        columnWidth: 60,
        onChange: () => { },
    }
    const onFinish = ({ status, kisname }) => {
        if (kisname === undefined) {
            kisname = '';
        }
        // console.log('///////////////value//////////////');
        // console.log(status);
        // console.log('//////////////value///////////////');
        // console.log('///////////////productname//////////////');
        // console.log(productname);
        // console.log('//////////////productname///////////////');
        loadKisGoodsDataSource({ 'status': status, 'kisname': kisname });

    }
    const onFinishFailed = () => {
        console.log('///////////////onFinishFailed//////////////');
        console.log('//////////////onFinishFailed///////////////');
    }
    const [open, setOpen] = useState(false);
    const [size, setSize] = useState();
    const onClose = () => {
        setOpen(false);
    };
    const showLargeDrawer = () => {
        setSize('large');
        setOpen(true);
    };
    return (
        <div className='w-full bg-white flex overflow-auto min-h-full p-6 '>
            <Space size={[8, 16]} direction='vertical' className='w-full'>
                <Card
                    title="商品列表"
                    extra={<Badge count={614}><Button onClick={showLargeDrawer}>差异日志</Button></Badge>}
                    bordered={false}
                />
                <Space size={[8, 16]} >
                    <Space.Compact block>
                        <Button onClick={() => { }}>新增</Button>
                        {/* <Button onClick={asyncUpdataKisGoods}>同步</Button> */}
                        <Button onClick={() => { }}>上架</Button>
                        <Button onClick={() => { }}>下架</Button>
                    </Space.Compact>
                    <Form
                        name="basic"
                        layout="inline"
                        initialValues={{
                            status: 'normal',
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Space.Compact block>
                            <Input
                                className="site-input-split"
                                style={{
                                    width: 80,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }}
                                placeholder="商品状态"
                                disabled
                            />
                            <Form.Item noStyle={true} name='status'>
                                <Select>
                                    <Option value="normal">热销</Option>
                                    <Option value="forbidden">下架</Option>
                                </Select>
                            </Form.Item>
                            <Input
                                className="site-input-split"
                                style={{
                                    width: 80,
                                    borderLeft: 0,
                                    borderRight: 0,
                                    pointerEvents: 'none',
                                }}
                                placeholder="商品名称"
                                disabled
                            />
                            <Form.Item noStyle={true} name='kisname'>
                                <Input placeholder="蓝海128v角磨机（无刷)" className='w-56' />
                            </Form.Item>
                            <Button type='primary' htmlType='submit'>搜索</Button>
                        </Space.Compact>
                    </Form>
                </Space>
                <Table

                    columns={columns}
                    rowSelection={rowSelection}
                    // expandable={{
                    //     expandedRowRender,
                    //     defaultExpandedRowKeys: ['0'],
                    // }}
                    expandable={expandableProps}
                    dataSource={dataSource}
                />
            </Space>
            <Drawer
                title={`差异日志（2023-11-18 ）`}
                placement="right"
                size={size}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose} type='link'>完整日志</Button>
                    </Space>
                }
            >
                <Timeline
                    items={[
                        {
                            children: <><p className='font-black'>物料分组差异14条</p><p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>已处理</Button></p><p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p><p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p></>,
                        },
                        {
                            children: <>
                                <p className='font-black'>金蝶物料差异114条</p>
                                <p>原物料信息：金色水钻头 112元；当前物料信息：金色水钻头 118元。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原物料信息：金色水钻头 112元；当前物料信息：金色水钻头 118元。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原物料信息：金色水钻头 112元；当前物料信息：金色水钻头 118元。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原物料信息：金色水钻头 112元；当前物料信息：金色水钻头 118元。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                            </>,
                        },
                        {
                            children: <>
                                <p className='font-black'>商城商品差异600条</p>
                                <p>原商品信息：金色水钻头 112元 双十一二送一；当前物料信息：金色水钻头 118元 双十二7.5折。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原商品信息：金色水钻头 112元 双十一二送一；当前物料信息：金色水钻头 118元 双十二7.5折。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原商品信息：金色水钻头 112元 双十一二送一；当前物料信息：金色水钻头 118元 双十二7.5折。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原商品信息：金色水钻头 112元 双十一二送一；当前物料信息：金色水钻头 118元 双十二7.5折。<Button type='link'>已处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                                <p>原分组信息：水钻头；当前分组信息：金色水钻头。<Button type='link'>去处理</Button></p>
                            </>,
                        }
                    ]}
                />
            </Drawer>
        </div>
    );
};
export default GoodsList;