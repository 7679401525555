import { takeLatest, call, put, all } from 'redux-saga/effects';//固定写法，引入saga，也就使用控制异步任务
import { imageSuccess, imageFail, imageDeleteSuccess, imageDeleteFail } from './imageSlice';
import { signOutRequest } from '../sign/signSlice';
import request from '../../../utils/request';

// 保存图片
export function* imageRequest({ payload }) {
    try {
        const res = yield call(request.post, '/admin/image/save', payload);
        switch (res.data.code) {
            case 200:
                yield put(imageSuccess(res.data.data));
                break;
            case 401:
                yield put(signOutRequest());
                break;
            case 'default':
                yield put(imageFail(res.data.msg));
                break;
        }
    } catch (err) {
        yield put(imageFail());
    }
}
// 删除图片
export function* imageDeleteRequest({ payload }) {
    try {
        const res = yield call(request.post, '/admin/image/delete', payload);
        if (res.data.code === 200) {
            yield put(imageDeleteSuccess());
        } else {
            yield put(imageDeleteFail());
        }
    } catch (err) {
        yield put(imageDeleteFail());
    }
}

export default all([
    takeLatest('image/imageRequest', imageRequest),
    takeLatest('image/imageDeleteRequest', imageDeleteRequest),
]);
