import React from 'react'
import MyLayout from './layout/layout'
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/dashboard/dashboard';
import CustomerList from './pages/customer/list';
import GoodsList from './pages/goods/list';
import GoodsAdd from './pages/tools/goodsadd';
import Signin from './pages/sign/signin';
import CustomerPreferenceList from './pages/tools/customerpreferencelist';
import LuckyMoneyList from './pages/promotion/luckymoney';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<MyLayout />} >
                <Route path="/admin/dashboard" element={<Dashboard />} />
                <Route path="/admin/goods/list" element={<GoodsList />} />
                <Route path="/admin/customer/list" element={<CustomerList />} />
                <Route path="/admin/tools/customerpreferencelist" element={<CustomerPreferenceList />} />
                <Route path="/admin/tools/goodsadd" element={<GoodsAdd />} />
                <Route path="/admin/tools/goodsadd" element={<GoodsAdd />} />
                <Route path="/admin/promotion/luckymoney" element={<LuckyMoneyList />} />
            </Route>
            <Route path='/signin' element={<Signin />} />
        </Routes>
    )
}

export default App