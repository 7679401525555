import React, { useState } from 'react';
import { useSelector } from "react-redux";
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    SendOutlined,
    UserOutlined,
    VideoCameraOutlined,
    DashboardOutlined
} from '@ant-design/icons';
import { Layout, Menu, Button, Watermark } from 'antd';
import { useNavigate, useLocation, Navigate, Outlet } from 'react-router-dom';
import HeaderRight from './components/header/headerright';

const { Header, Sider, Content } = Layout;

/**
 * 查找当前选中的menu菜单的值
 * @param key
 * @returns
 */
const findOpenKeys = (key, menus) => {
    const result = [];
    const findInfo = (arr) => {
        arr.forEach((item) => {
            if (key.includes(item.key)) {
                result.push(item.key);
                if (item.children) {
                    findInfo(item.children); // 使用递归的方式查找当前页面刷新之后的默认选中项
                }
            }
        });
    };
    findInfo(menus);
    return result;
};

const sideMenuData = [
    {
        key: '/admin/dashboard',
        icon: <DashboardOutlined />,
        label: '控制台',
    },
    {
        key: '/admin/tools',
        icon: <SendOutlined />,
        label: '便捷工具',
        children: [
            {
                key: '/admin/tools/customerpreferencelist',
                label: '购物偏好查询'
            },
            {
                key: '/admin/goods/list',
                label: '商品列表'
            },
            {
                key: '/admin/customer/list',
                label: '客户列表'

            }
        ]
    },
    {
        key: '/admin/promotion',
        icon: <SendOutlined />,
        label: '营销工具',
        children: [
            {
                key: '/admin/promotion/luckymoney',
                label: '抽奖红包'
            }
        ]
    }

];

const MyLayout = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const tmpOpenKeys = findOpenKeys(pathname, sideMenuData);
    const logined = useSelector(state => state.sign.logined);
    const userinfo = useSelector(state => state.sign.userinfo);

    if (logined) {
        return (
            <Layout style={{ width: '100vw', height: '100vh' }} className='bg-black'>
                <Sider trigger={null} collapsible collapsed={collapsed}>
                    <div className="demo-logo-vertical text-2xl text-white text-center my-3">{collapsed ? '订单' : '订单plus'}</div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        defaultOpenKeys={tmpOpenKeys}
                        defaultSelectedKeys={tmpOpenKeys}
                        onClick={({ key }) => {
                            navigate(key);
                        }}
                        items={sideMenuData}
                    />
                </Sider>
                <Layout>
                    <Header className="flex flex-row justify-between bg-white p-0">
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{
                                fontSize: '16px',
                                width: 64,
                                height: 64,
                            }}
                        />
                        <HeaderRight />
                    </Header>
                    {/* <Watermark content={userinfo.mobile} className="bg-gray-100 overflow-auto p-4 h-full" > */}
                    <div className='bg-gray-100 overflow-auto p-4 h-full'>
                        <Outlet />
                    </div>
                    {/* </Watermark> */}
                </Layout>
            </Layout>
        );
    } else {
        return <Navigate to="/signin" />
    }
};

export default MyLayout;