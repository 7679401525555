import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import GroupTree from './components/grouptree'
import TableList from './components/tablelist'
import { Divider, Space, DatePicker, Button, Select, Dropdown, Input, Card, App, Form, Modal } from 'antd';
import { MailOutlined, SettingOutlined, StarOutlined, HeartOutlined, SmileOutlined } from '@ant-design/icons';
import Searchfilter from './components/searchfilter'
import Tagselect, { event } from './components/tagselect'
import Smsselect, { smsevent } from './components/smsselect'
import SyncCustomer from './components/synccustomer'
import request from '../../utils/request'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import ExportJsonExcel from "js-export-excel";

const CustomerPerenceList = () => {
    const { message, modal, notification } = App.useApp();
    const { RangePicker } = DatePicker;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);//客户列表
    const [lines, setLines] = useState([]);//记录线路名称
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [grouppath, setGroupPath] = useState([]);//记录物料分组
    const [filter, setFilter] = useState({});//记录查询条件
    const [dataSource, setDataSource] = useState();
    const [total, setTotal] = useState(0);
    const [oprateid, setOprateid] = useState('');
    const platform = useSelector(state => state.sign.userinfo.shopname)
    let event = useRef();
    let smsevent = useRef();
    let syncevent = useRef();

    const groupTreeCallBack = (value) => {
        setGroupPath(value)
    }

    const showAdvancedCallBack = () => {
        setShowAdvanced(!showAdvanced);
    }
    /**
     * 记录操作日志
     */
    const oprateLog = async () => {
        const ret = await request.post('/admin/web/customer/oprateLog', { grouppath: grouppath, filter: filter, platform: platform })
        setOprateid(ret.data.insertid)
    }

    /**
     * 简单搜索
     * @returns 
     */
    const searchBtnOnClick = async () => {
        if (grouppath.length === 0) {
            message.error('请选择物料分组')
            return false;
        }
        const ret = await request.post('/admin/web/customer/preference/list', { grouppath: grouppath, filter: filter })
        switch (ret.data.code) {
            case 200:
                setDataSource(ret.data.data)
                setTotal(ret.data.data.length)
                break;
            case 401:
                message.error(ret.data.msg)
                break;
            default:
                message.error(ret.data.msg)
                break;
        }
    }
    /**
     * 高级搜索
     * @param {*} value 
     */
    const submitCallBack = async (value) => {
        if (grouppath.length === 0) {
            message.error('请至少选择一种物料')
            return
        }
        oprateLog();
        const ret = await request.post('/admin/web/customer/preference/advanced/list', { grouppath: grouppath, filter: value })
        switch (ret.data.code) {
            case 200:
                setDataSource(ret.data.data)
                setTotal(ret.data.data.length)
                break;
            case 401:
                message.error(ret.data.msg)
                break;
            default:
                message.error(ret.data.msg)
                break;
        }
    }
    /**
     * 发送短信
     * @returns 
     */
    const sendSMS = async () => {
        if (selectedRowKeys.length === 0) {
            message.error('请至少选中一个客户')
            return false;
        }
        const ret = await request.post('/admin/web/customer/sendsms/ad', { 'mobiles': selectedRowKeys, 'templatecode': 'SMS_216834146' })
        switch (ret.data.code) {
            case 200:
                message.success(ret.data.msg)
                break;
            case 401:
                message.error(ret.data.msg)
                break;
            default:
                message.error(ret.data.msg)
                break;
        }
    }
    /**
     * 同步到App
     * @returns 
     */
    const syncToApp = async () => {
        if (selectedRowKeys.length === 0) {
            message.error('请至少选中一个客户')
            return false;
        }
        const ret = await request.post('/admin/web/customer/data/sync', { 'customers': selectedRowKeys, 'oprateid': oprateid, 'platform': platform })
        switch (ret.data.code) {
            case 200:
                message.success(ret.data.msg)
                break;
            case 401:
                message.error(ret.data.msg)
                break;
            default:
                message.error(ret.data.msg)
                break;
        }
    }

    //简单搜索时间变化
    const onDataChange = (data, datastring) => {
        // const begintime = datastring[0] ? dayjs(datastring[0] + ' 00:00:00').unix() : '';
        // const endtime = datastring[1] ? dayjs(datastring[1] + ' 23:59:59').unix() : '';
        setFilter({ dateat: 'between', datevalue: datastring });
    }
    const items = [
        {
            label: '更多功能一',
            key: 'action1',
            icon: <MailOutlined />,
        },
        {
            label: '更多功能二',
            key: 'action2',
            icon: <MailOutlined />,
        }
    ];
    const menuProps = {
        items,
        onClick: (e) => {
            switch (e.key) {
                case 'sendSMS':
                    sendSMS();
                    break;
                default:
                    break;
            }
        },
    };
    //选中客户
    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    //高级搜索线路变化记录
    const onChangeCallBack = (newLines, selectedOptions) => {

        setLines(newLines.map((item) => {
            return item[1];
        }));
    };
    return (
        <div className='bg-white flex overflow-auto min-h-full p-6 '>

            <div className='w-60 pr-3' >
                <GroupTree callBack={groupTreeCallBack} />
            </div >
            <Divider type="vertical" style={{ height: 'inherit' }} />
            <div className=' flex-1 pl-3'>
                <Space direction="vertical" size="middle" className='w-full'>
                    <div className='flex justify-between items-center'>
                        <div>
                            <Space>
                                <Space.Compact block>
                                    <Button onClick={
                                        () => {
                                            return (Modal.confirm({
                                                icon: <></>,
                                                title: '选择客户标签',
                                                content: <Tagselect event={event} customers={selectedRowKeys} />,
                                                onOk: () => { event.current.onSubmit(); message.success('更新成功') }
                                            }))
                                        }
                                    }>打标签</Button>
                                    {/* <Button onClick={
                                        () => {
                                            return (Modal.confirm({
                                                icon: <></>,
                                                width: 700,
                                                title: '选择短信模板',
                                                content: <Smsselect smsevent={smsevent} customers={selectedRowKeys} />,
                                                onOk: () => { smsevent.current.onSubmit(); message.success('更新成功') }
                                            }))
                                        }
                                    }>发送短信</Button> */}
                                    <Button onClick={
                                        () => {
                                            message.success('导出功能开发中')
                                        }
                                    }>导出筛选结果</Button>
                                    <Dropdown.Button menu={menuProps} onClick={
                                        () => {
                                            return (Modal.confirm({
                                                icon: <></>,
                                                width: 700,
                                                title: '同步客户选项',
                                                content: <SyncCustomer syncevent={syncevent} lines={lines} data={dataSource} platform={platform} aid={oprateid} />,
                                                onOk: () => { syncevent.current.onSubmit(); message.success('更新成功') }
                                            }))
                                        }
                                    }>
                                        同步到app
                                    </Dropdown.Button>
                                </Space.Compact>
                                <Space.Compact block>
                                    <Space.Compact block>
                                        <Input
                                            className="site-input-split"
                                            style={{
                                                width: 80,
                                                borderLeft: 0,
                                                borderRight: 0,
                                                pointerEvents: 'none',
                                            }}
                                            placeholder="下单日期"
                                            disabled
                                        />
                                        <RangePicker onChange={onDataChange} />
                                        <Button onClick={() => { searchBtnOnClick(); oprateLog() }}>搜索</Button>
                                    </Space.Compact>
                                </Space.Compact>
                            </Space>
                        </div>
                        <div>
                            <Space.Compact block>
                                <Button type='link' onClick={() => { setShowAdvanced(!showAdvanced) }}>高级搜索</Button>
                                <Button icon={<SettingOutlined />} />
                            </Space.Compact>
                        </div>
                    </div>
                    {showAdvanced && <Card className='w-full' hoverable > <Searchfilter showAdvancedCallBack={showAdvancedCallBack} submitCallBack={submitCallBack} onChangeCallBack={onChangeCallBack} /> </Card>}
                    <TableList data={dataSource} total={total} callBack={onSelectChange} selectedRowKeys={selectedRowKeys} />
                </Space>
            </div >
        </div >
    )
}

export default CustomerPerenceList