import { createSlice } from "@reduxjs/toolkit";

const signSlice = createSlice({
    name: "sign",
    initialState: {
        loading: false,
        userinfo: {},
        logined: false,
        msg: '',
    },
    reducers: {
        //登录开始
        signInRequest: (state, action) => {
            state.loading = true;
            state.msg = '';
        },
        //登录成功
        signInSuccess: (state, action) => {
            state.loading = false;
            state.logined = true;
            state.userinfo = action.payload;
        },
        //登录失败
        signInFail: (state, action) => {
            state.msg = action.payload;
            state.logined = false;
        },
        showMsgToggle: (state, action) => {
            state.msg = '';
        },
        //退出登录
        signOutRequest: (state, action) => {
            state.logined = false;
            state.userinfo = {};
        }
    },
});

export const signReducer = signSlice.reducer;
export const { signInRequest, signInSuccess, signInFail, signOutRequest, showMsgToggle } = signSlice.actions;
