import React, { useEffect, useState } from 'react'
import { Col, Card, Row, Progress, Avatar, Tooltip, Drawer, Button, App, Form, Input, Space, Table, Tag, Dropdown } from 'antd';
import { AntDesignOutlined, UserOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons';
import request from '../../utils/request'
import dayjs from 'dayjs';
import RedPackForm from './redpackfrom';


const Luckymoney = () => {
    const [form] = Form.useForm();
    const { message, modal, notification } = App.useApp();

    //切换滑出层
    const [open, setOpen] = useState(false);
    //红包列表数据
    const [datasource, setDatasource] = useState([]);
    const [detailList, setDetailList] = useState([]);
    //当前红包数据
    const [currentData, setCurrentData] = useState({});
    //新建(create)、编辑(edit)状态切换状态
    const [saveToggle, setSaveToggle] = useState('create');
    //打开滑出层
    const showDrawer = () => {
        setOpen(true);
    };
    //关闭滑出层
    const onClose = () => {
        setOpen(false);
    };
    const columns = [
        {
            title: '批次号',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>,
        },
        {
            title: '红包编号',
            dataIndex: 'age',
            key: 'age',
        },
        {
            title: '手机号',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '微信号',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '领取时间',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '红包状态',
            dataIndex: 'address',
            key: 'address',
        },
        {
            title: '操作',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <a>禁用该账户{record.name}</a>
                </Space>
            ),
        },
    ];
    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer'],
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser'],
        },
        {
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sydney No. 1 Lake Park',
            tags: ['cool', 'teacher'],
        },
    ];
    //红包按钮集合
    const btnlist = [
        {
            key: '1',
            label: (
                <a href='javascript:void(0)'>
                    编辑
                </a >
            )
        },
        {
            key: '2',
            label: (
                <a href='javascript:void(0)'>
                    禁用
                </a>
            ),
        },
        {
            key: '3',
            label: (
                <a href='javascript:void(0)'>
                    导出数据
                </a>
            ),
        }
    ];
    //红包按钮被点击
    const btnOnClick = (e, item) => {
        switch (e.key) {
            case '1'://编辑
                setOpen(true);
                setCurrentData(item);
                setSaveToggle('edit');
                break;
            case '2'://禁用
                forbiddenLuckyMoney(item.id);
                break;
            case '3'://导出数据
                exportLuckyMoney(item.id);
                break;

        }
    }
    //导出数据
    const exportLuckyMoney = async (id) => {
        const ret = await request.post('/admin/web/promotion/luckymoney/export', { id });
        switch (ret.data.code) {
            case 200:
                notification.success({
                    message: ret.data.msg,
                    description:
                        <div>红包数据非常重要，下载并交给印刷厂家后请离家销毁,<a target='_blank' href={process.env.REACT_APP_BASE_URL + ret.data.data}> 点击下载</a></div>,
                    duration: 0,
                })
                // openNotification(ret.data.msg, ret.data.data);
                break;
            case 400:
                message.error(ret.data.msg);
                break;
            default:
                break;
        }
    }
    //禁用红包功能
    const forbiddenLuckyMoney = async (id) => {
        const ret = await request.post('/admin/web/promotion/luckymoney/forbidden', { id });
        switch (ret.data.code) {
            case 200:
                message.success(ret.data.msg, () => { window.location.reload(); });
                break;
            case 400:
                message.error(ret.data.msg);
                break;
            default:
                break;
        }
    }

    //请求红包列表
    const loadLuckyMoneyDataSource = async () => {
        const ret = await request.post('/admin/web/promotion/luckymoney/list');
        switch (ret.data.code) {
            case 200:
                setDatasource(ret.data.data.list)
                break;
            case 400:
                break;
            default:
                break;
        }
    }
    //请求红包详情列表
    const loadLuckyMoneyDetail = async () => {
        const ret = await request.post('/admin/web/promotion/luckymoney/detail/list');
        switch (ret.data.code) {
            case 200:
                setDetailList(ret.data.data.list)
                break;
            case 400:
                break;
            default:
                break;
        }
    }
    //项目初始化
    useEffect(() => {
        loadLuckyMoneyDataSource();
        loadLuckyMoneyDetail();
    }, [])

    return (
        <div className=' bg-white p-4'>
            <Row
                gutter={[12, 16]}
            >
                {
                    datasource.length > 0 ? (
                        datasource.map((item) => {
                            const percent = (item.grabs / item.total) * 100;
                            const datebegin = dayjs(item.act_begintime * 1000).format('YYYY-MM-DD');
                            const dateend = dayjs(item.act_endtime * 1000).format('YYYY-MM-DD');
                            return (<Col className="gutter-row" span={6}>
                                <Card
                                    bordered={true}
                                    className='border-gray-300'
                                    title={`${item.act_name}`}
                                    extra={
                                        <Dropdown menu={{ items: btnlist, onClick: (e) => { btnOnClick(e, item) } }}>
                                            <a onClick={(e) => e.preventDefault()} >
                                                <Space>操作<DownOutlined /></Space>
                                            </a>
                                        </Dropdown>
                                    }
                                >
                                    <Row
                                        gutter={4}
                                    >
                                        <Col className="gutter-row" span={16}>
                                            <div className='text-sm text-gray-400 leading-6'>{`批次号：${item.batchno}`}</div>
                                            <div className='text-sm text-gray-400'>{`祝福语：${item.wishing}`}</div>
                                            <div className='text-sm text-gray-400 leading-8'>{`备　注：${item.remark}`}</div>
                                            <div className='text-sm text-gray-400 leading-6'>{`${datebegin}~${dateend}`}</div>
                                        </Col>
                                        <Col className="gutter-row text-right" span={8}>
                                            <Progress type="circle" percent={percent} size={80} format={(percent) => percent} />
                                        </Col>
                                    </Row>
                                    <Avatar.Group>
                                        {
                                            item.receiver.map((receiver) => {
                                                return (
                                                    <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                                                )
                                            })
                                        }
                                    </Avatar.Group>
                                </Card>
                            </Col>)
                        })
                    ) : (
                        <></>
                    )
                }
                <Col className="gutter-row" span={6}>
                    <Card bordered={true} className='border-dashed border-gray-300'>
                        <div className='h-36 flex justify-center items-center'>
                            <Avatar
                                style={{
                                    backgroundColor: '#f56a00',
                                }}
                                icon={<PlusOutlined />}
                                onClick={() => { setSaveToggle('create'); setCurrentData({ 'send_name': '蓝牛建材' }); showDrawer() }}
                            />
                        </div>
                    </Card>
                </Col>
                <Col span={24}><Table columns={columns} dataSource={detailList} /></Col>
            </Row>
            <Drawer title={saveToggle === 'create' ? '创建红包' : '编辑红包'} placement="right" width={800} onClose={onClose} open={open}>
                <RedPackForm setOpen={setOpen} data={currentData} savetype={saveToggle} />
            </Drawer>
        </div >
    )
}

export default Luckymoney