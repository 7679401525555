import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import React from 'react';
import {
    Button,
    Checkbox,
    Col,
    Form,
    Row,
    Select,
    Space,
    Input,
} from 'antd';
import PhotoList from './components/photolist'
import DropDown from './components/dropdown'
const { Option } = Select;
const formItemLayout = {
    labelCol: {
        span: 4,
    },
    wrapperCol: {
        span: 20,
    },
};
const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e?.fileList;
};
const onFinish = (values) => {
    console.log('Received values of form: ', values);
};
const App = () => (
    <div className='bg-white py-6'>
        <Form
            name="validate_other"
            {...formItemLayout}
            onFinish={onFinish}
            initialValues={{
                'checkbox-group': ['lanhai', 'lanniu'],
            }}
        >
            <Form.Item
                label="物料名称"
                name="materialname"
                rules={[
                    {
                        required: true,
                        message: '请输入您的物料名称!',
                    },
                ]}
            >
                <Input className='w-132' />
            </Form.Item>
            <Form.Item
                label="价格"
                name="price"
                rules={[
                    {
                        required: true,
                        message: '请输入物料价格!',
                    },
                ]}
            >
                <Input prefix="￥" suffix="RMB" className='w-36' />
            </Form.Item>
            {/* <Form.Item
                label="属性"
                name="price"
                rules={[
                    {
                        required: true,
                        message: '请输入物料价格!',
                    },
                ]}
            >
                <DropDown />
            </Form.Item> */}
            <Form.Item name="checkbox-group" label="关联金蝶账套">
                <Checkbox.Group
                    style={{
                        width: '100%',
                    }}
                    onChange={() => {
                        console.log('/////////////////////////////');
                        console.log('/////////////////////////////');
                    }}
                >
                    <Row>
                        <Col span={2} >
                            <Checkbox
                                value="lanhai"
                                style={{
                                    lineHeight: '32px',
                                }}
                            >
                                蓝海
                            </Checkbox>
                        </Col>
                        <Col span={2}>
                            <Checkbox
                                value="lanniu"
                                style={{
                                    lineHeight: '32px',
                                }}
                            >
                                蓝牛
                            </Checkbox>
                        </Col>
                    </Row>
                </Checkbox.Group>
            </Form.Item>
            <Form.Item
                name="defaultimg"
                label="主图"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                    {
                        required: true,
                        message: '请上传物料主图!',
                    },
                ]}
            >
                <PhotoList />
            </Form.Item>
            <Form.Item label="细节图片">
                <PhotoList />
            </Form.Item>
            <Form.Item label="详情图">
                <PhotoList />
            </Form.Item>

            <Form.Item
                wrapperCol={{
                    span: 12,
                    offset: 6,
                }}
            >
                <Space>
                    <Button type="primary" htmlType="submit">
                        确定
                    </Button>
                    <Button htmlType="reset">重置</Button>
                </Space>
            </Form.Item>
        </Form>
    </div >

);
export default App;