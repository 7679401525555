import React, { useEffect, useState, useMemo } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Badge, Dropdown, Space, Table, Modal, Button, Card, Tag } from 'antd';
import request from '../../utils/request'
const items = [
    {
        key: '1',
        label: 'Action 1',
    },
    {
        key: '2',
        label: 'Action 2',
    },
];

const App = () => {

    const [dataSource, setDataSource] = useState([])
    const [expanded, setExpanded] = useState([]);

    const loadCustomerDataSource = async () => {
        const res = await request.post('/admin/web/customer/list', {})
        switch (res.data.code) {
            case 200:
                setDataSource(res.data.data)
                break;
            case 401:
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        loadCustomerDataSource();
    }, [])



    const columns = [
        {
            title: '销售组',
            width: 120,
            dataIndex: 'aid'
        },
        Table.EXPAND_COLUMN,
        {
            title: '手机号',
            width: 100,
            dataIndex: 'mobile',
        },
        {
            title: '客户画像',
            dataIndex: 'tags',
            render: (value, record, index) => {
                return (
                    <>
                        {value.map((item) => <Tag color={`#${item.color}`} >{item.tagname}</Tag>)}
                    </>
                )
            },
        },
        {
            title: '所属商城',
            width: 100,
            dataIndex: 'platform',
        },
        {
            title: '订单',
            width: 200,
            dataIndex: 'totalmoney',
            render: (value, record, index) => '￥' + (value / 1000).toFixed(2) + '/' + record.totalorders + '单',
        },
        {
            title: '所在地',
            width: 200,
            render: (value, record, index) => record.province + '/' + record.city + '/' + record.county,
        },
        {
            title: '备注',
            width: 100,
            dataIndex: 'memo'
        },
        {
            title: '操作',
            width: 100,
            key: 'operation',
            render: () => <a>编辑</a>,
        },
    ];

    const rowSelection = {
        columnWidth: 60,
        onChange: () => { },
    }

    return (
        <div className='w-full bg-white flex overflow-auto min-h-full p-6 '>
            <Space size={[8, 16]} direction='vertical' className='w-full'>
                <Card
                    title="客户列表"
                    bordered={false}
                >
                    <p>Card content</p>
                </Card>
                <Space.Compact block>
                    <Button type='primary' onClick={() => { }}>新增</Button>
                    <Button onClick={() => { }}>同步</Button>
                    <Button onClick={() => { }}>下架</Button>
                </Space.Compact>
                <Table
                    columns={columns}
                    rowSelection={rowSelection}
                    dataSource={dataSource}
                />
            </Space>
        </div>
    );
};
export default App;