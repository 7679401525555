import { createSlice } from "@reduxjs/toolkit";

const imageSlice = createSlice({
    name: "image",
    initialState: {
        loading: false,
        formdata: { 'fileList': [] },
        logined: false,
        showmsg: false,
        msg: '',
    },
    reducers: {
        //更新表单
        updateFormData: (state, { payload }) => {
            // state.one.push({ 'titlea': action.payload })
            // state.name = { 'aaa': payload }
            // state.title.push(payload)
            let data = { ...state.formdata, ...payload };
            if (data === null) {
                data = [];
            }
            state.formdata = data;
        },
        //去提交
        imageRequest: (state, { payload }) => {
            state.loading = true
        },
        //提交成功
        imageSuccess: (state, { payload }) => {
            state.loading = false
        },
        //提交失败
        imageFail: (state, { payload }) => {
            state.loading = false
        },
        //删除元素
        imageDeleteRequest: (state, { payload }) => {
            state.loading = true
        },
        //提交成功
        imageDeleteSuccess: (state, { payload }) => {
            state.loading = false
            // const index = state.formdata.fileList.findIndex(p => p.id === payload.uid);
        },
        //提交失败
        imageDeleteFail: (state, { payload }) => {
            state.loading = false
        },
    },
});

export const imageReducer = imageSlice.reducer;
export const { updateFormData, imageRequest, imageSuccess, imageFail, imageDeleteRequest, imageDeleteSuccess, imageDeleteFail } = imageSlice.actions;
