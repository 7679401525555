import axios from 'axios';

const API_HOST = process.env.REACT_APP_BASE_URL;

const TIMEOUT = 50000;

export const instance = axios.create({
    baseURL: API_HOST,
    timeout: TIMEOUT,
    withCredentials: true,
});

export default instance;