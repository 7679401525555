import React, { useState, useRef, useEffect, useImperativeHandle } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button, App } from 'antd';
import request from '../../../utils/request'
let index = 0;
export const event = { onSubmit: () => { } };
const Tagselect = (props) => {
    const { message } = App.useApp();
    useImperativeHandle(props.event, () => ({
        onSubmit: () => {
            updateCustomerTag()
        },
    }));
    const [items, setItems] = useState([]);
    const [name, setName] = useState('');
    const [tagids, setTagids] = useState([]);

    const inputRef = useRef(null);
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e, id) => {
        e.preventDefault();
        setItems([...items, { label: name, value: id }]);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
    };
    //添加标签
    const addTag = async (e) => {
        const ret = await request.post('/admin/web/customer/tag/add', { 'tagname': name })
        switch (ret.data.code) {
            case 200:
                addItem(e, ret.data.data.insertid);
                break;
            case 401:
                break;
            default:
                break;
        }
    }
    //加载标签
    const loadTags = async () => {
        const ret = await request.post('/admin/web/customer/tag/list')
        switch (ret.data.code) {
            case 200:
                setItems(ret.data.data)
                break;
            case 401:
                break;
            default:
                break;
        }
    }
    //更新用户标签
    const updateCustomerTag = async () => {
        const ret = await request.post('/admin/web/customer/addtag', { customerids: props.customers, tagids: tagids })

    }
    useEffect(() => {
        loadTags()
    }, [])
    return (
        <Select
            style={{
                width: 300,
            }}
            placeholder="选择客户标签"
            mode='multiple'
            onChange={(value) => {
                setTagids(value);
                console.log('///////////////value//////////////');
                console.log(value);
                console.log('//////////////value///////////////');
            }}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider
                        style={{
                            margin: '8px 0',
                        }}
                    />
                    <Space
                        style={{
                            padding: '0 8px 4px',
                        }}
                    >
                        <Input
                            placeholder="添加一个新标签"
                            ref={inputRef}
                            value={name}
                            onChange={onNameChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={addTag}>
                            添加
                        </Button>
                    </Space>
                </>
            )}
            options={items.map((item) => ({
                label: item.label,
                value: item.value,
            }))}
        />
    );
};
export default Tagselect;