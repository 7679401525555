import React, { useState, useImperativeHandle } from 'react';
import { Divider, Input, Select, Button, Radio, Form, Space, Tooltip, App } from 'antd';
import request from '../../../utils/request'
let index = 0;
export const syncevent = { onSubmit: () => { } };
const SyncCustomer = (props) => {
    const data = props.data;
    const lines = props.lines;
    const aid = props.aid;
    const platform = props.platform;
    const { message } = App.useApp();
    const [syncValues, setSyncValues] = useState({ type: 1, begin: 1, end: '100' });
    useImperativeHandle(props.syncevent, () => ({
        onSubmit: () => {
            request.post('/admin/web/customer/data/sync', { 'type': syncValues.type, 'begin': syncValues.begin, 'end': syncValues.end, 'data': data, 'lines': lines, 'aid': aid, 'platform': platform }).then((res) => {
                console.log('///////////////res.data.data//////////////');
                console.log(res.data.data);
                console.log('//////////////res.data.data///////////////');
            })
        },
    }));


    const onValuesChange = (changedValues, allValues) => {
        setSyncValues(allValues)
    }

    return (
        <div>
            <Form
                name="basic"
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    type: 1,
                    begin: 1,
                    end: 100
                }}
                onValuesChange={onValuesChange}
                autoComplete="off"
            >
                <Form.Item
                    label="同步方式"
                    name="type"
                >
                    <Radio.Group >
                        <Radio value={1}>覆盖</Radio>
                        <Radio value={2}>追加</Radio>
                    </Radio.Group>
                </Form.Item>
                <Space.Compact block>
                    <Form.Item
                        label="同步数量"
                        name='begin'
                        rules={[
                            {
                                required: true,
                                message: '请填写同步起始行',
                            }
                        ]}
                        extra="选中线路符合条件的前100条">
                        <Input placeholder="起始行" />
                    </Form.Item>
                    <Form.Item >
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="~"
                            disabled
                        />
                    </Form.Item>
                    <Form.Item
                        name='end'
                        rules={[
                            {
                                required: true,
                                message: '请填写同步结束行',
                            },
                        ]}>
                        <Input placeholder="结束行" />
                    </Form.Item>
                </Space.Compact>
            </Form >

        </div>
    );
};
export default SyncCustomer;