import { all } from 'redux-saga/effects';//合并整个应用的全部saga，方便调用
//下面的saga是需要我们编写的
import sign from './sign/signSaga';
import task from './task/taskSaga';
import image from './image/imageSaga';

export default function* rootSaga() {
    return yield all([
        sign,
        task,
        image
    ]);
}