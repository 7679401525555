import React from 'react';
import ReactDOM from 'react-dom/client';
import 'antd/dist/reset.css';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { store, persistor } from './store/store';

import AppContainer from './app';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider, App } from 'antd';
import { HashRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor}>
            <HashRouter>
                <App>
                    <ConfigProvider
                        locale={zhCN}
                        theme={{
                            token: {
                                colorPrimary: '#1677ff',
                            },
                        }}
                    >
                        <AppContainer />
                    </ConfigProvider>
                </App>
            </HashRouter>
        </PersistGate>
    </Provider>
);