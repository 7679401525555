import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input, Row, Col, App } from 'antd';
import { signInRequest, showMsgToggle } from '../../../store/modules/sign/signSlice'
import CountDown from './countdown';
import request from '../../../utils/request'
import './signinForm.css'

const Signin = () => {
    const msg = useSelector(state => state.sign.msg);
    const dispatch = useDispatch();
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [content, setContent] = useState('获取验证码');
    const [mobile, setMobile] = useState('');
    const { count, run } = CountDown();

    const { message, modal, notification } = App.useApp();

    const onFinish = (values) => {
        dispatch(signInRequest(values));
    };

    const onValuesChange = (changedValues, allValues) => {
        setMobile(changedValues);
    };

    const msgToggle = () => {
        dispatch(showMsgToggle());
    };

    const handleSendVcode = async () => {
        const res = await request.post('/sendsms/vcode', mobile);
        switch (res.data.code) {
            case 200:
                message.success(res.data.msg);
                setBtnDisabled(true);
                setContent(`${count}秒后`);
                run();
                break;
            case 400:
                message.error(res.data.msg);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (btnDisabled) {
            setContent(`${count}秒后`)
        }
        if (count === 0) {
            setContent('获取验证码')
            setBtnDisabled(false)
        }
    }, [count])

    useEffect(() => {
        if (msg !== '') {
            message.error(msg, () => { msgToggle() });
        }
    }, [msg])

    return (
        <div className='w-full px-32'>
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                    remember: true,
                }}
                size='large'
                onFinish={onFinish}
                onValuesChange={onValuesChange}
            >
                <Form.Item
                    name="mobile"
                    rules={[
                        {
                            required: true,
                            message: '请输入您的手机号码!',
                        },
                    ]}
                >
                    <Input prefix={<UserOutlined />} placeholder="手机号码" />
                </Form.Item>
                <Form.Item>
                    <Row gutter={8}>
                        <Col span={16}>
                            <Form.Item
                                name="vcode"
                                noStyle
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入您的验证码!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={<LockOutlined />}
                                    type="text"
                                    placeholder="验证码"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            {
                                btnDisabled ? (<Button>{`${content}`}</Button>) : (<Button onClick={handleSendVcode}>{`${content}`}</Button>)
                            }

                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle >
                        <Checkbox>记住我</Checkbox>
                    </Form.Item>
                    <a href="" className='login-form-forgot'>
                        忘记密码
                    </a>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="login-form-button">
                        立即登录
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default Signin;