import { takeLatest, call, put, all } from 'redux-saga/effects';//固定写法，引入saga，也就使用控制异步任务
import { signInSuccess, signInFail, signOutRequest } from './signSlice';
import request from '../../../utils/request';


// 登陆
export function* signinRequest({ payload }) {
    try {
        const res = yield call(request.post, '/web/smsvcodelogin', payload);
        if (res.data.code === 200) {
            yield put(signInSuccess(res.data.data));
        } else {
            yield put(signInFail(res.data.msg));
        }
    } catch (err) {
        yield put(signInFail());
    }
}

export default all([
    takeLatest('sign/signInRequest', signinRequest),
]);
