import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { signOutRequest } from "../../../store/modules/sign/signSlice"

const HeaderRight = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const username = useSelector(state => state.sign.userinfo);
    const items = [
        {
            label: '个人信息中心',
            key: 'dashboard',
            onClick: ({ key }) => {
                navigate(key)
            }
        },
        {
            type: 'divider',
        },
        {
            label: '退出登录',
            key: '/signin',
            onClick: ({ key }) => {
                dispatch(signOutRequest());
                // navigate(key)
            }
        }
    ];
    return (<Dropdown className='mr-5'
        menu={{
            items,
        }}
        trigger={['click']}
    >

        <Space wrap>
            <Button type="link" shape="circle">
                超级管理员：{username.username}
            </Button>
            <DownOutlined />
        </Space>
    </Dropdown>)
};
export default HeaderRight;