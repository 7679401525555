import { takeLatest, call, put, all } from 'redux-saga/effects';//固定写法，引入saga，也就使用控制异步任务
import { addTaskSuccess, addTaskFail, updateTaskSuccess, updateTaskFail } from './taskSlice';
import { signOutRequest } from '../sign/signSlice';
import request from '../../../utils/request';
import { message } from 'antd';


// 添加任务
export function* addTaskRequest({ payload }) {
    try {
        const res = yield call(request.post, '/admin/task/addtask', payload);
        switch (res.data.code) {
            case 200:
                yield put(addTaskSuccess(res.data));
                break;
            case 401:
                yield put(signOutRequest());
                break;
            default:
                yield put(addTaskFail(res.data.msg));
                break;
        }
    } catch (err) {
        message.error('服务器内部错误')
    }
}
// 添加任务
export function* updateTaskRequest({ payload }) {
    try {
        const res = yield call(request.post, '/admin/task/updatetask', payload);
        switch (res.data.code) {
            case 200:
                yield put(updateTaskSuccess(res.data));
                break;
            case 401:
                yield put(signOutRequest());
                break;
            default:
                yield put(updateTaskFail(res.data.msg));
                break;

        }
    } catch (err) {
        message.error('服务器内部错误')
    }
}

export default all([
    takeLatest('task/addTaskRequest', addTaskRequest),
    takeLatest('task/updateTaskRequest', updateTaskRequest),
]);
