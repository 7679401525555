import React, { useState, useEffect } from 'react';
import { Tree, Input } from 'antd';
import request from '../../../utils/request'

const App = ({ callBack }) => {
    const initTreeData = [
        {
            title: '蓝海',
            key: '1',
            grouppath: '0,1,'
        },
        // {
        //     title: '蓝牛',
        //     key: '2',
        //     grouppath: '0,2,'
        // }
    ];
    const updateTreeData = (list, key, children) =>
        list.map((node) => {
            if (node.key === key) {
                return {
                    ...node,
                    children,
                };
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateTreeData(node.children, key, children),
                };
            }
            return node;
        });

    const [treeData, setTreeData] = useState(initTreeData);

    const loadGroupTree = async ({ key, children }) => {
        const ret = await request.post('/admin/web/goods/groupdata', { 'pid': key });
        switch (ret.data.code) {
            case 200:
                setTreeData((origin) =>
                    updateTreeData(origin, key, ret.data.data)
                )
                break;
            case 401:
                break;
            default:
                break;
        }
    }


    const { Search } = Input;
    // const [expandedKeys, setExpandedKeys] = useState(['0-0-0', '0-0-1']);
    // const [checkedKeys, setCheckedKeys] = useState(['0-0-0']);
    // const [selectedKeys, setSelectedKeys] = useState([]);
    // const [autoExpandParent, setAutoExpandParent] = useState(true);
    // const onExpand = (expandedKeysValue) => {
    //     console.log('onExpand', expandedKeysValue);
    //     setExpandedKeys(expandedKeysValue);
    //     setAutoExpandParent(false);
    // };
    // const onCheck = (checkedKeysValue) => {
    //     console.log('onCheck', checkedKeysValue);
    //     setCheckedKeys(checkedKeysValue);
    // };
    // const onSelect = (selectedKeysValue, info) => {
    //     console.log('onSelect', info);
    //     setSelectedKeys(selectedKeysValue);
    // };
    const onCheck = (keys, element) => {
        let arr = []
        if (element.checkedNodes.length > 0) {
            element.checkedNodes.map((item) => {
                arr.push(item.grouppath)
            })
        }
        callBack(arr)
    }
    const onChange = () => {
        console.log('///////////////change//////////////');
        console.log('//////////////change///////////////');
    }
    return (
        <div className='h-full'>
            <Search
                style={{
                    marginBottom: 8,
                }}
                placeholder="Search"
                onChange={onChange}
            />
            <Tree
                checkable
                // onExpand={onExpand}
                // expandedKeys={expandedKeys}
                // autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                // checkedKeys={checkedKeys}
                // onSelect={onSelect}
                // selectedKeys={selectedKeys}
                // treeData={treeData}
                loadData={loadGroupTree}
                treeData={treeData}
            />
        </div>
    );
};
export default App;