import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { Button, Form, Input, DatePicker, Space, Select, TimePicker, Cascader } from 'antd';
import { useEffect, useState } from 'react';
import request from '../../../utils/request'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker;
const { Option } = Select;

const App = ({ showAdvancedCallBack, submitCallBack, onChangeCallBack }) => {
    const { SHOW_CHILD } = Cascader;
    const userinfo = useSelector(state => state.sign.userinfo);
    const format = 'HH:mm';
    const [, forceUpdate] = useState({});
    const [userList, setUserList] = useState([]);

    const loadUserList = async () => {
        const res = await request.post('/admin/web/user/options/list', { filter: { shopname: userinfo.shopname, groupname: 'line' } })
        setUserList(res.data.data)
    }

    const onFinish = (values) => {
        const rangeTimeValue = values['datevalue'];
        const linenameValue = values['linename'];
        const filterValue = {
            ...values,
            'datevalue': rangeTimeValue !== undefined ? [
                rangeTimeValue[0].format('YYYY-MM-DD'),
                rangeTimeValue[1].format('YYYY-MM-DD'),
            ] : [],
            'linename': linenameValue !== undefined ? linenameValue.map((item) => {
                return item[1];
            }) : []
        };
        submitCallBack(filterValue);
        console.log('Success:', filterValue);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    // To disable submit button at the beginning.
    useEffect(() => {
        loadUserList();
        forceUpdate({});
    }, []);

    return (
        <>
            <Form
                name="basic"
                layout="inline"
                initialValues={{
                    dateat: 'between',
                    timebucketat: 'between',
                    totalmoneyat: 'between',
                    countat: 'between',
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Space size={[16, 16]} wrap>
                    <Space.Compact block>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 80,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="下单日期"
                            disabled
                        />
                        <Form.Item noStyle={true} name='dateat'>
                            <Select>
                                <Option value="between">介于</Option>
                                <Option value="out">以外</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle={true} name='datevalue'>
                            <RangePicker />
                        </Form.Item>
                    </Space.Compact>

                    <Space.Compact block>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 80,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="下单时段"
                            disabled
                        />
                        <Form.Item noStyle={true} name='timebucketat'>
                            <Select>
                                <Option value="between">介于</Option>
                                <Option value="out">以外</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle={true} name='timebucketbegin'>
                            <Select className='w-16'>
                                <Option value="0">00</Option>
                                <Option value="1">01</Option>
                                <Option value="2">02</Option>
                                <Option value="3">03</Option>
                                <Option value="4">04</Option>
                                <Option value="5">05</Option>
                                <Option value="6">06</Option>
                                <Option value="7">07</Option>
                                <Option value="8">08</Option>
                                <Option value="9">09</Option>
                                <Option value="10">10</Option>
                                <Option value="11">11</Option>
                                <Option value="12">12</Option>
                                <Option value="13">13</Option>
                                <Option value="14">14</Option>
                                <Option value="15">15</Option>
                                <Option value="16">16</Option>
                                <Option value="17">17</Option>
                                <Option value="18">18</Option>
                                <Option value="19">19</Option>
                                <Option value="20">20</Option>
                                <Option value="21">21</Option>
                                <Option value="22">22</Option>
                                <Option value="23">23</Option>
                            </Select>
                        </Form.Item>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item noStyle={true} name='timebucketend'>
                            <Select className='w-16'>
                                <Option value="0">00</Option>
                                <Option value="1">01</Option>
                                <Option value="2">02</Option>
                                <Option value="3">03</Option>
                                <Option value="4">04</Option>
                                <Option value="5">05</Option>
                                <Option value="6">06</Option>
                                <Option value="7">07</Option>
                                <Option value="8">08</Option>
                                <Option value="9">09</Option>
                                <Option value="10">10</Option>
                                <Option value="11">11</Option>
                                <Option value="12">12</Option>
                                <Option value="13">13</Option>
                                <Option value="14">14</Option>
                                <Option value="15">15</Option>
                                <Option value="16">16</Option>
                                <Option value="17">17</Option>
                                <Option value="18">18</Option>
                                <Option value="19">19</Option>
                                <Option value="20">20</Option>
                                <Option value="21">21</Option>
                                <Option value="22">22</Option>
                                <Option value="23">23</Option>
                            </Select>
                        </Form.Item>
                    </Space.Compact>

                    <Space.Compact block>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 80,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="下单金额"
                            disabled
                        />
                        <Form.Item noStyle={true} name='totalmoneyat'>
                            <Select>
                                <Option value="between">介于</Option>
                                <Option value="out">以外</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle={true} name='totalmoneymin'>
                            <Input
                                style={{
                                    width: 100,
                                    textAlign: 'center',
                                }}
                                placeholder="100"
                            />
                        </Form.Item>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item noStyle={true} name='totalmoneymax'>
                            <Input
                                className="site-input-right"
                                style={{
                                    width: 100,
                                    textAlign: 'center',
                                }}
                                placeholder="1000"
                            />
                        </Form.Item>
                    </Space.Compact>
                    <Space.Compact block>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 80,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="下单数量"
                            disabled
                        />
                        <Form.Item noStyle={true} name='countat'>
                            <Select>
                                <Option value="between">介于</Option>
                                <Option value="out">以外</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item noStyle={true} name='countmin'>
                            <Input
                                style={{
                                    width: 100,
                                    textAlign: 'center',
                                }}
                                placeholder="1"
                            />
                        </Form.Item>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 30,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="~"
                            disabled
                        />
                        <Form.Item noStyle={true} name='countmax'>
                            <Input
                                className="site-input-right"
                                style={{
                                    width: 100,
                                    textAlign: 'center',
                                }}
                                placeholder="10"
                            />
                        </Form.Item>
                    </Space.Compact>
                    <Space.Compact block>
                        <Input
                            className="site-input-split"
                            style={{
                                width: 80,
                                borderLeft: 0,
                                borderRight: 0,
                                pointerEvents: 'none',
                            }}
                            placeholder="线路名称"
                            disabled
                        />
                        <Form.Item noStyle={true} name='linename'>
                            <Cascader
                                style={{
                                    minWidth: 200,
                                    maxWidth: 400
                                }}
                                options={userList}
                                onChange={onChangeCallBack}
                                multiple
                                maxTagCount="responsive"
                                showCheckedStrategy={SHOW_CHILD}
                            />
                        </Form.Item>
                    </Space.Compact>
                    <Space.Compact block>
                        <Button type="primary" htmlType="submit">立即搜索</Button>
                        <Button>重置条件</Button>
                    </Space.Compact>
                    <Button type="link" onClick={showAdvancedCallBack}>收起高级搜索</Button>
                </Space>
            </Form >
        </>
    );
};
export default App;