import React from 'react'

const Dashboard = () => {
    return (
        <div>
            <div className='bg-white h-150'>
                Dashboard
            </div>
            <div className='bg-white h-150'>
                Dashboard
            </div>
            <div className='bg-white h-150'>
                Dashboard
            </div>
        </div>
    )
}

export default Dashboard